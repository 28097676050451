<template>
  <div class="auth-wrapper auth-v1 px-2 mt-1">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <price-x-logo/>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('titles.adventure_starts_here') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('titles.make_your_app_management_easy_and_fun') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
              class="auth-register-form mt-2"
              @submit.prevent="validationForm"
          >
            <b-form-group
                :label="$t('label.your_name')"
                label-for="name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="regName"
                    :state="errors.length > 0 ? false:null"
                    name="register-name"
                    :placeholder="$t('placeholder.how_you_want_to_call_you')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- email -->
            <b-form-group
                :label="$t('label.email')"
                label-for="email"
            >
              <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
              >
                <b-form-input
                    id="email"
                    v-model="regEmail"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
                :label="$t('label.password')"
                label-for="password"
            >
              <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
                :label="$t('label.confirm_password')"
                label-for="password_confirmation"
            >
              <validation-provider
                  #default="{ errors }"
                  name="password_confirmation"
                  rules="required"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="password_confirmation"
                      v-model="password_confirmation"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="register-password-confirmation"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
              >
                <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                    :value="true"
                    :not-checked-value="false"
                >
                  {{ $t('messages.i_agree_to') }}
                  <b-link>{{ $t('messages.privacy_policy_terms') }}</b-link>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-overlay :show="submitLoading" rounded
                       spinner-small
                       spinner-variant="primary">
              <b-button
                  variant="primary"
                  block
                  type="submit"
              >
                {{ $t('buttons.sign_up') }}
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('questions.already_have_an_account') }}</span>
          <b-link :to="{name:'auth.login'}">
            <span> {{ $t('buttons.sign_in_instead') }}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            {{ $t('messages.or') }}
          </div>
        </div>

        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
              variant="facebook"
              @click="handleFacebookLogin"
          >
            <feather-icon icon="FacebookIcon"/>
          </b-button>
          <b-button
              variant="google"
              @click="handleGoogleLogin"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" width="14" height="14" fill="#ffffff"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
          </b-button>
          <div style="width: 35px;">
            <vue-apple-login
                mode="logo-only"
                type="sign in"
                color="black"
                :border="true"
                :radius="15"
                width="100%"
                height="100%"
                logoSize="small"
                :logoPosition="0"
                :labelPosition="0"
                className="vue-apple-login"
                :onSuccess="callSuccess"
                :onFailure="callFail"
            >
            </vue-apple-login>
          </div>
        </div>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
  BOverlay
} from 'bootstrap-vue'
import PriceXLogo from '@core/layouts/components/Logo.vue'
import {required, email, password as passwordRule} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import useJwt from "@/auth/jwt/useJwt";
import {EventBus} from "@/plugins/event-bus";
import {mapActions} from "vuex";

export default {
  components: {
    PriceXLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BOverlay,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  metaInfo() {
    return {
      title: this.$t('page_titles.register')
    }
  },
  data() {
    return {
      regEmail: '',
      regName: '',
      password: '',
      password_confirmation: '',
      status: false,

      // validation rules
      required,
      email,
      passwordRule,
      submitLoading: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('user', ['fetch']),
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.submitLoading = true
          this.$http.post('/register', {
            name: this.regName,
            email: this.regEmail,
            password: this.password,
            password_confirmation: this.password_confirmation,
            status: this.status
          }).then(response => {
            useJwt.setToken(response.data.access_token)
            useJwt.setRefreshToken(response.data.refresh_token)
            this.fetch().then(userResponse => {
              this.$ability.update(userResponse.data.ability)
              this.$nextTick(() => {
                EventBus.$emit('login')
                this.$router.push('/')
              })
            })
            this.$ability.update(response.data.user_data.ability)
            this.$nextTick(() => {
              EventBus.$emit('login')
              this.$router.push('/')
            })
          }).catch(error => {
            this.$refs.registerForm.setErrors(error.response.data.errors)
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    handleFacebookLogin() {
      this.$http.get('/login/facebook').then(response => {
        window.location.href = response.data
      })
    },
    handleGoogleLogin() {
      this.$http.get('/login/google').then(response => {
        window.location.href = response.data
      })
    },
    handleAppleLogin() {
      this.$http.get('/login/apple').then(response => {
        window.location.href = response.data
      })
    },
    callSuccess(data){
      this.$router.push({name: 'auth.social', params: {driver: 'apple'}, query: {code: data.authorization.code}})
    },
    callFail(e){
      console.error(e)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
